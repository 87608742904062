import React, {useEffect} from "react";
import PageviewsChartWrapper from "./pageviewsChart.style";
import {Bar, Line} from "react-chartjs-2";
import {months} from "../../../helpers/constants";
import chroma from "chroma-js";

export default function PageviewsBartWidget({label, valueData, borderColor, shadowColor, valueLabel}){

    useEffect(()=>{

    },[label, valueData])

    const dataset1 = {
        label: label,
        fill: false,
        data: valueData,
        borderColor: borderColor,
        pointBorderColor: borderColor,
        pointBackgroundColor: borderColor,
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: shadowColor,
        backgroundColor: "#563c91",
        pointRadius: 4,
        pointBevelWidth: 3,
        pointBevelHighlightColor: "rgba(255, 255, 255, 0.75)",
        pointBevelShadowColor: shadowColor,
        pointHoverRadius: 6,
        pointHoverBevelWidth: 4.5,
        pointHoverInnerGlowWidth: 20,
        pointHoverInnerGlowColor: shadowColor,
        pointHoverOuterGlowWidth: 20,
        pointHoverOuterGlowColor: shadowColor
    };

    /*const dataset2 = {
        label: "Page Views",
        fill: false,
        data: [
            1230,
            1140,
            2400,
            2367,
            1350,
            1650,
            561,
            1410,
            702,
            2952,
            3072,
            2670
        ],
        borderColor: "#677484",
        pointBorderColor: "#677484",
        pointBackgroundColor: "#677484",
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: "rgba(103, 116, 132, 0.5)",
        pointRadius: 4,
        pointBevelWidth: 3,
        pointBevelHighlightColor: "rgba(255, 255, 255, 0.75)",
        pointBevelShadowColor: "rgba(103, 116, 132, 0.5)",
        pointHoverRadius: 6,
        pointHoverBevelWidth: 4.5,
        pointHoverInnerGlowWidth: 20,
        pointHoverInnerGlowColor: `rgba(103, 116, 132, 0.5)`,
        pointHoverOuterGlowWidth: 20,
        pointHoverOuterGlowColor: `rgba(103, 116, 132, 0.5)`
    };*/

    const data = {
        labels: months,
        datasets: [
            dataset1
        //, dataset2
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        tooltips: {
            mode: "index",
            intersect: false
        },
        hover: {
            mode: "nearest",
            intersect: true
        },
        spanGaps: true,
        scales: {
            xAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: "Month"
                    }
                }
            ],
            yAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: "Value"
                    }
                }
            ]
        }
    };

    return (
        <PageviewsChartWrapper>
            <div className="page-view-chart-widget-card">
                <div className="header fs-14 demi-bold-text row" style={{color:borderColor}}>{label} <div className="pl-3 demi-bold-text fs-14" style={{color:shadowColor}}>{valueLabel}</div></div>
                <div className="analytics">
                    <Bar data={data} height={240} options={options} />
                </div>
            </div>
        </PageviewsChartWrapper>
    );
};

