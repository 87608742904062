import React from 'react';
import classNames from 'classnames';
import HorizontalPerformanceWrapper from './horperformance.style'

const HorizontalPerformanceWidget = (props) => {

    const {
        className
    } = props;

    return (
        <HorizontalPerformanceWrapper>
            <div
                className={classNames(
                    "roe-card-style",
                    className
                )}
            >
                <div className="roe-card-body">
                    <div className="row ma-0 text-center">
                        <div className="col-lg-4">
                            <div className="flex-x pt-10">
                                <div className="mr-15">
                                    <button className="round-widget-button c-primary">
                                        <i className="fas fa-chalkboard-teacher fs-20"></i>
                                    </button>
                                </div>
                                <div>
                                    <div className="fs-15 bold-text widget-dark-color">
                                        Collaborateurs
                                    </div>
                                    <div className="fs-15 bold-text widget-grey-color ptb-6">
                                        {props.employeeStatObject && props.employeeStatObject.total1 ? props.employeeStatObject.total1 : "0" }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="flex-x pt-10">
                                <div className="mr-15">
                                    <button className="round-widget-button c-warning">
                                        <i className="fas fa-chalkboard-teacher fs-20"></i>
                                    </button>
                                </div>
                                <div>
                                    <div className="fs-15 bold-text widget-dark-color">
                                        Recrutements
                                    </div>
                                    <div className="fs-15 bold-text widget-grey-color ptb-6">
                                        {props.employeeStatObject && props.employeeStatObject.total2 ? props.employeeStatObject.total2 : "0" }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="flex-x pt-10">
                                <div className="mr-15">
                                    <button className="round-widget-button c-info">
                                        <i className="fas fa-chalkboard-teacher fs-20"></i>
                                    </button>
                                </div>
                                <div>
                                    <div className="fs-15 bold-text widget-dark-color">
                                        Départs
                                    </div>
                                    <div className="fs-15 bold-text widget-grey-color ptb-6">
                                        {props.employeeStatObject && props.employeeStatObject.total3 ? props.employeeStatObject.total3 : "0" }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </HorizontalPerformanceWrapper>
    );
};

export default HorizontalPerformanceWidget;