
export const AppName = "ERP ADM";
export const drawerWidth = "260px";
export const miniDrawerWidth = "80px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";

export const brownShoe = require("../assets/images/brown-shoe.jpeg");
export const speaker = require("../assets/images/speaker.jpeg");
export const loginBack = require("../assets/images/loginback.jpg");
export const iconDemo = require("../assets/images/roelogo.png");
export const roelogo2 = require("../assets/icons/logo2.png");
export const ForgotIcon = require("../assets/images/forgotpassword.svg");
export const locakscreenBack = require("../assets/images/lockscreen.jpg");
export const ProfileLockScreen = require("../assets/images/profile.jpg");
export const errorback = require("../assets/images/errorback.jpg");
export const feed1 = require("../assets/images/feed1.jpg");
export const feed2 = require("../assets/images/feed2.jpg");
export const friend1 = require("../assets/images/friend1.jpg");
export const friend2 = require("../assets/images/friend2.jpg");
export const friend3 = require("../assets/images/friend3.jpg");
export const friend4 = require("../assets/images/friend4.jpg");
export const friend5 = require("../assets/images/friend5.jpg");
export const friend6 = require("../assets/images/friend6.jpg");
export const media1 = require("../assets/images/media1.jpeg");
export const media2 = require("../assets/images/media2.jpeg");
export const media3 = require("../assets/images/media3.jpeg");
export const media4 = require("../assets/images/media4.jpeg");
export const media5 = require("../assets/images/media5.jpeg");
export const media6 = require("../assets/images/media6.jpeg");
export const media7 = require("../assets/images/media7.jpeg");
export const media8 = require("../assets/images/media8.jpeg");
export const media9 = require("../assets/images/media9.jpeg");
export const sidebar1 = require("../assets/images/sidebar1.jpg");
export const sidebar2 = require("../assets/images/sidebar2.jpg");
export const sidebar3 = require("../assets/images/sidebar3.jpg");
export const sidebar4 = require("../assets/images/sidebar4.jpg");
export const sidebar5 = require("../assets/images/sidebar5.jpg");
export const sidebar6 = require("../assets/images/sidebar6.jpg");
export const sidebar7 = require("../assets/images/sidebar7.jpg");
export const sidebar8 = require("../assets/images/sidebar8.jpg");
export const Img6464 = require("../assets/images/6464Img.svg");
export const boardBanner = require("../assets/images/boardbanner.jpeg");

export const people1 = require("../assets/images/boardImages/people1.jpeg");
export const people2 = require("../assets/images/boardImages/people2.jpeg");
export const people3 = require("../assets/images/boardImages/people3.jpeg");
export const people4 = require("../assets/images/boardImages/people4.jpeg");
export const people5 = require("../assets/images/boardImages/people5.jpeg");
export const people6 = require("../assets/images/boardImages/people6.jpeg");
export const people7 = require("../assets/images/boardImages/people7.jpeg");
export const people8 = require("../assets/images/boardImages/people8.jpeg");
export const people9 = require("../assets/images/boardImages/people9.jpeg");
export const people10 = require("../assets/images/boardImages/people10.jpeg");
export const people11 = require("../assets/images/boardImages/people11.jpeg");
export const people12 = require("../assets/images/boardImages/people12.jpeg");
export const people13 = require("../assets/images/boardImages/people13.jpeg");
export const people14 = require("../assets/images/boardImages/people14.jpeg");
export const people15 = require("../assets/images/boardImages/people15.jpeg");

export const cover1 = require("../assets/images/boardImages/cover1.jpeg");
export const cover2 = require("../assets/images/boardImages/cover2.jpeg");
export const cover3 = require("../assets/images/boardImages/cover3.jpeg");
export const cover4 = require("../assets/images/boardImages/cover4.jpg");
export const cover5 = require("../assets/images/boardImages/cover5.jpeg");
export const cover6 = require("../assets/images/boardImages/cover6.jpeg");
export const cover7 = require("../assets/images/boardImages/cover7.jpeg");
export const cover8 = require("../assets/images/boardImages/cover8.jpeg");
export const cover9 = require("../assets/images/boardImages/cover9.jpeg");
export const cover10 = require("../assets/images/boardImages/cover10.jpeg");

export const identity = require("../assets/icons/identity.png");
export const etatcivil = require("../assets/icons/etatcivil.png");
export const addressPostal = require("../assets/icons/addressPostal.png");
export const infoban = require("../assets/icons/infoban.png");
export const buildingEnterprise = require("../assets/icons/buildingEnterprise.png");

export const rem = require("../assets/icons/rem.png");
export const infor = require("../assets/icons/infor.png");
export const contract = require("../assets/icons/contract.png");
export const contact = require("../assets/icons/contact.png");
export const person = require("../assets/icons/person.png");

export const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
];

export const  month = [
    {
        key: 1,
        label: "Janvier",
        value: "01"
    },
    {
        key: 2,
        label: "Février",
        value: "02"
    },
    {
        key: 2,
        label: "Mars",
        value: "03"
    },
    {
        key: 2,
        label: "Avril",
        value: "04"
    },
    {
        key: 2,
        label: "Mai",
        value: "05"
    },
    {
        key: 2,
        label: "Juin",
        value: "06"
    },
    {
        key: 2,
        label: "Juillet",
        value: "07"
    },
    {
        key: 2,
        label: "Août",
        value: "08"
    },
    {
        key: 2,
        label: "Septembre",
        value: "09"
    },
    {
        key: 2,
        label: "Octobre",
        value: "10"
    },
    {
        key: 2,
        label: "Novembre",
        value: "11"
    },
    {
        key: 2,
        label: "Décembre",
        value: "12"
    }
]
export const getYear = () => {
    let actYear = new Date().getFullYear();
    let listYear = [];
    for (let i=0; i<10; i++){
        listYear.push({
            key: i,
            label: actYear - i,
            value: actYear - i
        },)
    }
    return listYear;
}

export const  year = [
    {
        key: 0,
        label: new Date().getFullYear(),
        value: new Date().getFullYear()
    },
    {
        key: 1,
        label: new Date().getFullYear()-1,
        value: new Date().getFullYear()-1
    },
    {
        key: 2,
        label: new Date().getFullYear()-2,
        value: new Date().getFullYear()-2
    },
    {
        key: 3,
        label: new Date().getFullYear()-3,
        value: new Date().getFullYear()-3
    },
    {
        key: 4,
        label: new Date().getFullYear()-4,
        value: new Date().getFullYear()-4
    },
    {
        key: 5,
        label: new Date().getFullYear()-5,
        value: new Date().getFullYear()-5
    },
    {
        key: 6,
        label: new Date().getFullYear()-6,
        value: new Date().getFullYear()-6
    },
    {
        key: 7,
        label: new Date().getFullYear()-7,
        value: new Date().getFullYear()-7
    }
]



