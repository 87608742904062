import React, {useState, useEffect} from "react";
import AdvancedSearch from "../employees/search/advanced.search";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import IntlMessages from "../../helpers/intlMessages";
import {AdaSelectBuilder} from "@adaming/ada-react-business-component";
import {BILLING_NAME, EMPLOYEE_NAME, getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../service/host";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {Controller, useForm} from "react-hook-form";
import * as specificService from "../../service/employee.service";
import LatestOrgActivity from "./components/InfoDoughnutWidget";
import {buildingEnterprise, getYear, identity, month, months, people1, year} from "../../helpers/constants";
import PageviewsChartWidget from "../../components/widgets/pageviewsChartWidget/PageviewsChartWidget";
import * as service from "../../service/crud.service";
import DoughnutChartComponent from "./components/doughnutChartComponent";
import HorizontalPerformanceWidget
    from "../../components/widgets/statisticswidgets/horizontalperformance/HorizontalPerformanceWidget";

import amountFormat from "../commun/amount.format"
import PageviewsBartWidget from "../../components/widgets/pageviewsChartWidget/PageviewsBarWidget";
import PageviewsBarstWidget from "../../components/widgets/pageviewsChartWidget/PageviewsBarsWidget";
import { Label } from "reactstrap";

export default function OrganizationStatistic(props) {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [organizationList, setOrganizationList] = useState([]);
    const [organization, setOrganization] = useState({})
    const [selectedOrganization, setSelectedOrganization] = useState({})

    const [salesObject, setSalesObject] = useState({})
    const [leftToPayObject, setLeftToPayObject] = useState({})

    const [salesObjectMinus1, setSalesObjectMinus1] = useState({})
    const [leftToPayObjectMinus1, setLeftToPayObjectMinus1] = useState({})

    const [salesObjectMinus2, setSalesObjectMinus2] = useState({})
    const [leftToPayObjectMinus2, setLeftToPayObjectMinus2] = useState({})

    const [employeeStatObject, setEmployeeStatObject] = useState({})
    const [newEmployeeStatObject, setNewEmployeeStatObject] = useState({})
    const [resignedEmployeeStatObject, setResignedEmployeeStatObject] = useState({})
    const [errorMessage, setErrorMessage] = React.useState("");

    const [yearList, setYearList] = useState(getYear());

    const [selectedObject, setSelectedObject] = useState({
        "organization": null,
        "year": null
    });

    const [object, setObject] = useState({
        "organization": null,
        "year": null
    });

    useEffect(()=>{
        service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, setOrganizationList)
    },[])

    const onSubmit = data => {
        if(!selectedObject.organization){
            setErrorMessage("L'organization est obligatoire");
        }else if(!selectedObject.year){
                setErrorMessage("L'année est obligatoire");
        }else{
            clearResults();
        setObject(selectedObject);
        setOrganization(selectedOrganization);
        console.log("object ", data);


        //Y YEAR
        service.get(getHostByAppName(BILLING_NAME),`reporting/organization/sales/${selectedObject.organization}/${selectedObject.year}`,callbackResSales,(error)=>console.log("error sales call",error))
        service.get(getHostByAppName(BILLING_NAME),`reporting/organization/leftToPay/${selectedObject.organization}/${selectedObject.year}`,callbackResLeftToPay,(error)=>console.log("error leftToPay call",error))
        //Y-1 YEAR
        service.get(getHostByAppName(BILLING_NAME),`reporting/organization/sales/${selectedObject.organization}/${selectedObject.year-1}`,callbackResSalesMinus1,(error)=>console.log("error sales call",error))
        //service.get(getHostByAppName(BILLING_NAME),`reporting/organization/leftToPay/${object.organization}/${object.year-1}`,callbackResLeftToPayMinus1,(error)=>console.log("error leftToPay call",error))
        //Y-2 YEAR
        service.get(getHostByAppName(BILLING_NAME),`reporting/organization/sales/${selectedObject.organization}/${selectedObject.year-2}`,callbackResSalesMinus2,(error)=>console.log("error sales call",error))
        //service.get(getHostByAppName(BILLING_NAME),`reporting/organization/leftToPay/${object.organization}/${object.year-2}`,callbackResLeftToPayMinus2,(error)=>console.log("error leftToPay call",error))


        service.get(getHostByAppName(EMPLOYEE_NAME),`reporting/count/${selectedObject.organization}/${selectedObject.year}`,callbackResEmployeeStat,(error)=>console.log("error count call",error))
        service.get(getHostByAppName(EMPLOYEE_NAME),`reporting/count/newEmployee/${selectedObject.organization}/${selectedObject.year}`,callbackResNewEmployee,(error)=>console.log("error count newEmployee call",error))
        service.get(getHostByAppName(EMPLOYEE_NAME),`reporting/count/resigned/${selectedObject.organization}/${selectedObject.year}`,callbackResResignedEmployee,(error)=>console.log("error count resigned call",error))
    }
}

    const callbackResNewEmployee = data =>{
        console.log("res new employee",data)
        setNewEmployeeStatObject(data)
    }

    const callbackResResignedEmployee = data =>{
        console.log("res resigned",data)
        setResignedEmployeeStatObject(data)
    }

    const callbackResSales = data =>{
        console.log("res sales",data)
        setSalesObject(data)
    }

    const callbackResLeftToPay = data =>{
        console.log("res Left to pay",data)
        setLeftToPayObject(data)
    }

    const callbackResSalesMinus1 = data =>{
        console.log("res sales",data)
        setSalesObjectMinus1(data)
    }

    const callbackResLeftToPayMinus1 = data =>{
        console.log("res Left to pay",data)
        setLeftToPayObjectMinus1(data)
    }

    const callbackResSalesMinus2 = data =>{
        console.log("res sales",data)
        setSalesObjectMinus2(data)
    }

    const callbackResLeftToPayMinus2 = data =>{
        console.log("res Left to pay",data)
        setLeftToPayObjectMinus2(data)
    }

    const callbackResEmployeeStat = data =>{
        console.log("res employee stat",data)
        setEmployeeStatObject(data)
    }

    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("year", selectedOption);
        if (selectedOption !== null) {
            setValue("year", selectedOption.value);
            setSelectedObject({...selectedObject, year: selectedOption.value})
        } else {
            setValue("year", null);
            setSelectedObject({...selectedObject, year: null})
        }
    };

    const handleChangeOrganization = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("organization", selectedOption);
        if (selectedOption !== null) {
            setValue("organization", selectedOption.key);
            setSelectedObject({...selectedObject, organization:selectedOption.key})
            setSelectedOrganization(selectedOption.object)
        } else {
            setValue("organization", null);
            setSelectedObject({...selectedObject, organization:null})
            setSelectedOrganization({})
        }
    };

    const dispatch = (data) =>{
        console.log("in dispatch", {...selectedObject, ...data})
        setSelectedObject({...selectedObject, ...data})
    }

    const clear = ()=>{
        setErrorMessage("");
        reset({
            "organization": null,
            "year": null
        })
        setObject({})
        setOrganization({})
        clearResults()
    }
    const clearResults = ()=>{
        console.log("salesObject", salesObject);
        setErrorMessage("");
        setLeftToPayObject({})
        setSalesObject({})
        setEmployeeStatObject({})
        setNewEmployeeStatObject({})
        setResignedEmployeeStatObject({})
        setSalesObjectMinus1({})
        setSalesObjectMinus2({})
    }

    return(
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <div className="display-3 grey--text mt-3">
                                        <Row>
                                            <Col>
                                                <Label>
                                                    <p style={{color: "red"}}> {errorMessage}</p>
                                                </Label>
                                            </Col>         
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="assignment.organization"/>
                                                    <Controller
                                                        as={<AdaSelect name={"organization"}/>}
                                                        name={"organization"}
                                                        isClearable
                                                        errors={errors}
                                                        options={organizationList}
                                                        onChange={handleChangeOrganization}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="common.year"/>
                                                    <Controller
                                                        as={<AdaSelect name={"year"}/>}
                                                        name={"year"}
                                                        isClearable
                                                        errors={errors}
                                                        options={yearList}
                                                        onChange={handleChangeYear}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                                {/*<Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <IntlMessages id="common.du"/>
                                                                    <AdaInputText
                                                                        type="date"
                                                                        name="fromDate"
                                                                        errors={errors}
                                                                        innerRef={register}
                                                                        onBlur={e => setObject({...object, fromDate: e.target.value})}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <IntlMessages id="common.au"/>
                                                                    <AdaInputText
                                                                        type="date"
                                                                        name="toDate"
                                                                        errors={errors}
                                                                        innerRef={register}
                                                                        onBlur={e => setObject({...object, toDate: e.target.value})}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>*/}
                                            </Col>
                                            <Col className="pt-3">
                                                <FormGroup>
                                                    <AdaButton className="c-danger col-xl-5" onClick={() => clear()}>
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>
                                                    <AdaButton
                                                        className="c-warning col-xl-6"
                                                        type="submit"
                                                        style={{marginLeft: 10}}
                                                    >
                                                        <IntlMessages id="common.search"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </fieldset>
                            </form>



                        </div>
                    </div>

                    <div className="roe-card-style mt-3">
                        <div className="roe-card-body mt-3">

                            <div style={{minHeight:200}} className="row justify-content-between">
                                <div  style={{width:"99.9%"}}>
                                        <LatestOrgActivity
                                            name={"Organisation"}
                                            icon={buildingEnterprise}
                                            title={organization && organization.id ? organization.name : ""}
                                            description={organization.description ? organization.description : "ND"}
                                            site={organization.web ? organization.web : "ND"}
                                            telephone={organization.contactInformation && organization.contactInformation.phone ? organization.contactInformation.phone : "ND"}
                                            email={organization.contactInformation && organization.contactInformation.email ? organization.contactInformation.email : "ND"}
                                            fax={organization.contactInformation && organization.contactInformation.fax ? organization.contactInformation.fax : "ND"}
                                            statut={organization.enable === true ? "Activé" : "Désactivé"}
                                            owner={organization.owner}
                                            activityArea={organization.activityArea}
                                        />

                                </div>


                                <div style={{width:"99%"}} className="pt-2">
                                    <PageviewsBarstWidget labelY={`Chiffre d'affaires ${object.year ? object.year : ""} : `}
                                                          labelLY={`Chiffre d'affaires ${object.year ? object.year -1 : ""} : `}
                                                          labelTwoYAgo={`Chiffre d'affaires ${object.year ? object.year -2 : ""} : `}
                                                          valueLabelY={organization && organization.id && object.year && salesObject && salesObject.totalAmount ? amountFormat(salesObject.totalAmount) : ""}
                                                          valueDataY={organization && organization.id && object.year && salesObject && salesObject.valueList ? salesObject.valueList : []}

                                                          valueLabelLY={organization && organization.id && object.year && salesObjectMinus1 && salesObjectMinus1.totalAmount ? amountFormat(salesObjectMinus1.totalAmount) : ""}
                                                          valueDataLY={organization && organization.id && object.year && salesObjectMinus1 && salesObjectMinus1.valueList ? salesObjectMinus1.valueList : []}

                                                          valueLabelTwoYAgo={organization && organization.id && object.year && salesObjectMinus2 && salesObjectMinus2.totalAmount ? amountFormat(salesObjectMinus2.totalAmount) : ""}
                                                          valueDataTwoYAgo={organization && organization.id && object.year && salesObjectMinus2 && salesObjectMinus2.valueList ? salesObjectMinus2.valueList : []}



                                                          borderColor={"#09bd92"} shadowColor={"rgba(0, 196, 134, 0.5)"}/>
                                </div>

                                <div style={{width:"99%"}}  className="pt-2">
                                    <PageviewsBartWidget label={`Montant reste à payer  ${object.year ? object.year : ""} : `}
                                                         valueLabel={organization && organization.id && object.year && leftToPayObject && leftToPayObject.totalAmount ? amountFormat(leftToPayObject.totalAmount) :""}
                                                         valueData={organization && organization.id && object.year && leftToPayObject && leftToPayObject.valueList ? leftToPayObject.valueList : []}
                                                         borderColor={"#c4a655"}
                                                         shadowColor={"rgba(196,104,5,0.5)"}/>
                                </div>



                                {/*<div style={{width:"99%"}}  className="pt-2">
                                    <PageviewsBarstWidget labelY={`Montant reste à payer  ${object.year ? object.year : ""} : `}
                                                         labelLY={`Montant reste à payer  ${object.year ? object.year -1 : ""} : `}
                                                         labelTwoYAgo={`Montant reste à payer  ${object.year ? object.year -2 : ""} : `}

                                                         valueLabelY={organization && organization.id && object.year && leftToPayObject && leftToPayObject.totalAmount ? amountFormat(leftToPayObject.totalAmount) :""}
                                                         valueDataY={organization && organization.id && object.year && leftToPayObject && leftToPayObject.valueList ? leftToPayObject.valueList : []}

                                                          valueLabelLY={organization && organization.id && object.year && leftToPayObjectMinus1 && leftToPayObjectMinus1.totalAmount ? amountFormat(leftToPayObjectMinus1.totalAmount) :""}
                                                          valueDataLY={organization && organization.id && object.year && leftToPayObjectMinus1 && leftToPayObjectMinus1.valueList ? leftToPayObjectMinus1.valueList : []}

                                                          valueLabelTwoYAgo={organization && organization.id && object.year && leftToPayObjectMinus2 && leftToPayObjectMinus2.totalAmount ? amountFormat(leftToPayObjectMinus2.totalAmount) :""}
                                                          valueDataTwoYAgo={organization && organization.id && object.year && leftToPayObjectMinus2 && leftToPayObjectMinus2.valueList ? leftToPayObjectMinus2.valueList : []}


                                                          borderColor={"#c4a655"}
                                                         shadowColor={"rgba(196,104,5,0.5)"}/>
                                </div>*/}

                                {/*<div style={{width:"49%"}} className="pt-2">
                                    <PageviewsBartWidget label={`Chiffre d'affaires ${object.year ? object.year : ""} : `} valueLabel={organization && organization.id && object.year && salesObject && salesObject.totalAmount ? amountFormat(salesObject.totalAmount) : ""} valueData={organization && organization.id && object.year && salesObject && salesObject.valueList ? salesObject.valueList : []} borderColor={"#09bd92"} shadowColor={"rgba(0, 196, 134, 0.5)"}/>
                                </div>


                                <div style={{width:"49%"}}  className="pt-2">
                                    <PageviewsBartWidget label={`Montant reste à payer  ${object.year ? object.year : ""} : `}
                                                         valueLabel={organization && organization.id && object.year && leftToPayObject && leftToPayObject.totalAmount ? amountFormat(leftToPayObject.totalAmount) :""}
                                                         valueData={organization && organization.id && object.year && leftToPayObject && leftToPayObject.valueList ? leftToPayObject.valueList : []}
                                                         borderColor={"#c4a655"}
                                                         shadowColor={"rgba(196,104,5,0.5)"}/>
                                </div>

                                <div style={{width:"49%"}} className="pt-2">
                                    <PageviewsBartWidget label={`Chiffre d'affaires ${object.year ? object.year - 1 : ""} : `}
                                                         valueLabel={organization && organization.id && object.year && salesObjectMinus1 && salesObjectMinus1.totalAmount ? amountFormat(salesObjectMinus1.totalAmount) : ""}
                                                         valueData={organization && organization.id && object.year && salesObjectMinus1 && salesObjectMinus1.valueList ? salesObjectMinus1.valueList : []}
                                                         borderColor={"#09bd92"} shadowColor={"rgba(0, 196, 134, 0.5)"}/>
                                </div>


                                <div style={{width:"49%"}}  className="pt-2">
                                    <PageviewsBartWidget label={`Montant reste à payer  ${object.year ? object.year - 1 : ""} : `}
                                                         valueLabel={organization && organization.id && object.year && leftToPayObjectMinus1 && leftToPayObjectMinus1.totalAmount ? amountFormat(leftToPayObjectMinus1.totalAmount) :""}
                                                         valueData={organization && organization.id && object.year && leftToPayObjectMinus1 && leftToPayObjectMinus1.valueList ? leftToPayObjectMinus1.valueList : []} borderColor={"#c4a655"} shadowColor={"rgba(196,104,5,0.5)"}/>
                                </div>

                                <div style={{width:"49%"}} className="pt-2">
                                    <PageviewsBartWidget label={`Chiffre d'affaires ${object.year ? object.year -2  : ""} : `}
                                                         valueLabel={organization && organization.id && object.year && salesObjectMinus2 && salesObjectMinus2.totalAmount ? amountFormat(salesObjectMinus2.totalAmount) : ""}
                                                         valueData={organization && organization.id && object.year && salesObjectMinus2 && salesObjectMinus2.valueList ? salesObjectMinus2.valueList : []} borderColor={"#09bd92"} shadowColor={"rgba(0, 196, 134, 0.5)"}/>
                                </div>


                                <div style={{width:"49%"}}  className="pt-2">
                                    <PageviewsBartWidget label={`Montant reste à payer  ${object.year ? object.year-2 : ""} : `}
                                                         valueLabel={organization && organization.id && object.year && leftToPayObjectMinus2 && leftToPayObjectMinus2.totalAmount ? amountFormat(leftToPayObjectMinus2.totalAmount) :""}
                                                         valueData={organization && organization.id && object.year && leftToPayObjectMinus2 && leftToPayObjectMinus2.valueList ? leftToPayObjectMinus2.valueList : []} borderColor={"#c4a655"} shadowColor={"rgba(196,104,5,0.5)"}/>
                                </div>*/}

                                <div style={{width:"99%"}}  className="pt-2">
                                    <HorizontalPerformanceWidget employeeStatObject={employeeStatObject}/>
                                </div>

                                <div style={{width:"49%"}}  className="pt-2">
                                    <PageviewsBartWidget label={"Recrutements :"} valueLabel={organization && organization.id && object.year && newEmployeeStatObject && newEmployeeStatObject.total1 ? `${newEmployeeStatObject.total1} Collaborateur(s)` : "0 Collaborateur(s)"}  valueData={organization && organization.id && object.year && newEmployeeStatObject && newEmployeeStatObject.valueList ? newEmployeeStatObject.valueList : []} borderColor={"#0f11c4"} shadowColor={"rgba(7,14,220,0.5)"}/>
                                </div>

                                <div style={{width:"49%"}}  className="pt-2">
                                    <PageviewsBartWidget label={"Départs :"} valueLabel={organization && organization.id && object.year && resignedEmployeeStatObject && resignedEmployeeStatObject.total1 ? `${resignedEmployeeStatObject.total1} Collaborateur(s)` : "0 Collaborateur(s)"}   valueData={organization && organization.id && object.year && resignedEmployeeStatObject && resignedEmployeeStatObject.valueList ? resignedEmployeeStatObject.valueList : []} borderColor={"#0f11c4"} shadowColor={"rgba(7,14,220,0.5)"}/>
                                </div>






                                {/*<div style={{width:"49%"}}  className="pt-2">
                                    <PageviewsChartWidget label={"Collaborateurs en inter-contrat :"} valueLabel={organization && organization.id && object.year ? " 19 Collaborateurs" : ""}  valueData={organization && organization.id && object.year ? [10, 18, 45, 32, 30, 28, 20, 25, 15, 11, 16, 19] : []} borderColor={"#c46269"} shadowColor={"rgba(220,53,69,0.5)"}/>
                                </div>*/}



                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
