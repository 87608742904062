import React from "react";
import axios from "axios";
import {getConfigObject} from "@adaming/ada-react-component";


export const getAllPersonSelect = (host, context, setState, state) => {
    let listForSelect = [];
    console.log("get ", context);
    axios.get(`${host}/${context}/`, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item =>
                listForSelect.push({
                    key: item.id,
                    label: item.firstName + " " + item.lastName,
                    value: item.id,
                    object: item
                })
            );
            console.log(context, listForSelect)
            if (state !== undefined) {
                listForSelect.concat(state);
            }
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
};

export const getAllResourcesSelect = (host, context, setState, state) => {
    let listForSelect = [];
    console.log("get ", context);
    axios.get(`${host}/${context}/`, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item =>
                listForSelect.push({
                    key: item.id,
                    label: item.id + " : " + item.designation,
                    value: item.id,
                    object: item
                })
            );
            console.log(context, listForSelect)
            if (state !== undefined) {
                listForSelect.concat(state);
            }
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
};
