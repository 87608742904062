import CollaboratorStatistic from "../views/reporting/collaborator.statistic"
import OrganizationStatistic from "../views/reporting/organization.statistic"
import ClientStatistic from "../views/reporting/client.statistic"
import Maintenance from "../views/pages/error/maintenance";

const employeeRoutes = [
    {path: "/employees", component: CollaboratorStatistic},
    {path: "/organizations", component: OrganizationStatistic},
    {path: "/clients", component: ClientStatistic},
    { path: "/maintenance", component: Maintenance}
];

export default employeeRoutes;