import React, {useState, useEffect} from "react";
import AdvancedSearch from "../employees/search/advanced.search";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import IntlMessages from "../../helpers/intlMessages";
import {AdaSelectBuilder} from "@adaming/ada-react-business-component";
import {
    ASSIGNMENT_NAME,
    BILLING_NAME, CRA_NAME,
    EMPLOYEE_NAME,
    getHostByAppName,
    NOMENCLATURE_NAME,
    ORGANIZATION_NAME
} from "../../service/host";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {Controller, useForm} from "react-hook-form";
import * as specificService from "../../service/employee.service";
import LatestActivity from "./components/UserInfoDoughnutWidget";
import {identity, month, months, people1, person, getYear} from "../../helpers/constants";
import PageviewsChartWidget from "../../components/widgets/pageviewsChartWidget/PageviewsChartWidget";
import AnalyticsProcessWidgets from "../../components/widgets/analyticsProcessWidgets/AnalyticsProcessWidgets";
import MinibarWidgets from "../../components/widgets/chartwidgets/minibar/MinibarWidgets";
import * as service from "../../service/crud.service";
import amountFormat from "../commun/amount.format";
import PageviewsBartWidget from "../../components/widgets/pageviewsChartWidget/PageviewsBarWidget";
import { Label } from "reactstrap";

export default function CollaboratorStatistic(props) {
    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [employeeList, setEmployeeList] = useState([]);
    const [employee, setEmployee] = useState({})
    const [yearList, setYearList] = useState(getYear());
    const [salesObject, setSalesObject] = useState({})
    const [workingDayObject, setWorkingDayObject] = useState({})
    const [icDayObject, setIcDayObject] = useState({})
    const [trainingDayObject, setTrainingDayObject] = useState({})
    const [leavesDayObject, setLeavesDayObject] = useState({})
    const [errorMessage, setErrorMessage] = React.useState("");


    const [object, setObject] = useState({
        "employee": null,
        "year": null
    });

    useEffect(()=>{
        specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
    },[])

    const onSubmit = data => {
        if(!object.employee){
            setErrorMessage("Le collaborateur est obligatoire");
        }else if(!object.year){
                setErrorMessage("L'année est obligatoire");
        }else{
            setErrorMessage("");

            service.get(getHostByAppName(BILLING_NAME),`reporting/employee/sales/${object.employee}/${object.year}`,callbackResSales,(error)=>console.log("error sales call",error))
            service.get(getHostByAppName(CRA_NAME),`reporting/employee/workingday/${object.employee}/${object.year}`,callbackResWorkingDay,(error)=>console.log("error contracts number call",error))
            service.get(getHostByAppName(CRA_NAME),`reporting/employee/icday/${object.employee}/${object.year}`,callbackResICDay,(error)=>console.log("error contracts number call",error))
            service.get(getHostByAppName(CRA_NAME),`reporting/employee/leaveday/${object.employee}/${object.year}`,callbackResLeaveDay,(error)=>console.log("error contracts number call",error))
            service.get(getHostByAppName(CRA_NAME),`reporting/employee/trainingday/${object.employee}/${object.year}`,callbackResTrainingDay,(error)=>console.log("error contracts number call",error))
        }
    }

    const callbackResWorkingDay = data =>{
        console.log("res Working Day number",data)
        setWorkingDayObject(data)
    }

    const callbackResICDay = data =>{
        console.log("res Working Day number",data)
        setIcDayObject(data)
    }

    const callbackResLeaveDay = data =>{
        console.log("res Leave Day number",data)
        setLeavesDayObject(data)
    }

    const callbackResTrainingDay = data =>{
        console.log("res Working Day number",data)
        setTrainingDayObject(data)
    }

    const callbackResSales = data =>{
        console.log("res sales",data)
        setSalesObject(data)
    }

    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("year", selectedOption);
        if (selectedOption !== null) {
            setValue("year", selectedOption.value);
            setObject({...object, year: selectedOption.value})
        } else {
            setValue("year", null);
            setObject({...object, year: null})
        }
    };

    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("employee", selectedOption);
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
            setObject({...object, employee:selectedOption.key})
            setEmployee(selectedOption.object)
        } else {
            setValue("employee", null);
            setObject({...object, employee:null})
            setEmployee({})
        }
    };

    const dispatch = (data) =>{
        console.log("in dispatch", {...object, ...data})
        setObject({...object, ...data})
    }

    const clear = ()=>{
        reset({
            "employee": null,
                "year": null
        })
        setObject({})
        setEmployee({})
        setSalesObject({})
        setIcDayObject({})
        setLeavesDayObject({})
        setWorkingDayObject({})
    }

    return(
        <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <fieldset>
                                            <div className="display-3 grey--text mt-3">
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            <p style={{color: "red"}}> {errorMessage}</p>
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        
                                                        <FormGroup>
                                                            <IntlMessages id="sidebar.employees"/>
                                                            <Controller
                                                                as={<AdaSelect name={"employee"}/>}
                                                                name={"employee"}
                                                                isClearable
                                                                errors={errors}
                                                                options={employeeList}
                                                                onChange={handleChangeEmployee}
                                                                control={control}
                                                                innerRef={register}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <IntlMessages id="common.year"/>
                                                        <Controller
                                                            as={<AdaSelect name={"year"}/>}
                                                            name={"year"}
                                                            isClearable
                                                            errors={errors}
                                                            options={yearList}
                                                            onChange={handleChangeYear}
                                                            control={control}
                                                            innerRef={register}
                                                        />
                                                        </FormGroup>
                                                        {/*<Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <IntlMessages id="common.du"/>
                                                                    <AdaInputText
                                                                        type="date"
                                                                        name="fromDate"
                                                                        errors={errors}
                                                                        innerRef={register}
                                                                        onBlur={e => setObject({...object, fromDate: e.target.value})}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <IntlMessages id="common.au"/>
                                                                    <AdaInputText
                                                                        type="date"
                                                                        name="toDate"
                                                                        errors={errors}
                                                                        innerRef={register}
                                                                        onBlur={e => setObject({...object, toDate: e.target.value})}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>*/}
                                                    </Col>
                                                    <Col className="pt-3">
                                                        <FormGroup>
                                                            <AdaButton className="c-danger col-xl-5" onClick={() => clear()}>
                                                                <IntlMessages id="action.common.cancel"/>
                                                            </AdaButton>
                                                            <AdaButton
                                                                className="c-warning col-xl-6"
                                                                type="submit"
                                                                style={{marginLeft: 10}}
                                                            >
                                                                <IntlMessages id="common.search"/>
                                                            </AdaButton>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                    </fieldset>
                                </form>



                            </div>
                        </div>

                        <div className="roe-card-style mt-3">
                            <div className="roe-card-body mt-3">

                                <div style={{minHeight:200}} className="row justify-content-between">
                                    <div  style={{width:"99.9%"}}>

                                        {
                                        <LatestActivity
                                            icon={person}
                                            title={employee && employee.id ? employee.civility+". "+employee.firstName+" "+employee.lastName : ""}
                                            email={employee.contactInformation && employee.contactInformation.email ? employee.contactInformation.email : "ND"}
                                            telephone={employee.contactInformation !== undefined ? employee.contactInformation.mobile : "ND"}
                                            statut={employee.enable === true ? "Activé" : "Désactivé"}
                                            categ={employee.category}
                                            func={employee.function}
                                            organ={employee.organization}
                                            secnum={employee.socialSecurityNumber ? employee.socialSecurityNumber :"ND"}
                                        />

                                    }
                                    </div>

                                    <div style={{width:"49%"}} className="pt-2">
                                        <PageviewsBartWidget label={"Chiffre d'affaires :"} valueLabel={employee && employee.id && object.year && salesObject && salesObject.totalAmount ? amountFormat(salesObject.totalAmount) : ""}  valueData={employee && employee.id && object.year && salesObject && salesObject.valueList ? salesObject.valueList : []}  borderColor={"#09bd92"} shadowColor={"rgba(0, 196, 134, 0.5)"}/>
                                    </div>

                                    <div style={{width:"49%"}}  className="pt-2">
                                        <PageviewsBartWidget label={"Jours travaillés : "} valueLabel={employee && employee.id && object.year && workingDayObject && workingDayObject.total ? workingDayObject.total : ""} valueData={employee && employee.id && object.year && workingDayObject && workingDayObject.valueList ? workingDayObject.valueList : []}  borderColor={"#c4a646"} shadowColor={"rgba(196,104,5,0.5)"}/>
                                    </div>

                                    <div style={{width:"49%"}}  className="pt-2">
                                        <PageviewsBartWidget label={"Jours en inter-contrat :"} valueLabel={employee && employee.id && object.year && icDayObject && icDayObject.total ? icDayObject.total : ""}  valueData={employee && employee.id && object.year && icDayObject && icDayObject.valueList ? icDayObject.valueList : []} borderColor={"#c46269"} shadowColor={"rgba(220,53,69,0.5)"}/>
                                    </div>

                                    <div style={{width:"49%"}}  className="pt-2">
                                        <PageviewsBartWidget label={"Absences :"} valueLabel={employee && employee.id && object.year && leavesDayObject && leavesDayObject.total? leavesDayObject.total : "" } valueData={employee && employee.id && object.year && leavesDayObject && leavesDayObject.valueList ? leavesDayObject.valueList : []} borderColor={"#0f11c4"} shadowColor={"rgba(15,17,196,0.5)"}/>
                                    </div>

                                    <div style={{width:"49%"}}  className="pt-2">
                                        <PageviewsBartWidget label={"Formations :"} valueLabel={employee && employee.id && object.year && trainingDayObject && trainingDayObject.total ? trainingDayObject.total : ""}  valueData={employee && employee.id && object.year && trainingDayObject && trainingDayObject.valueList ? trainingDayObject.valueList : []} borderColor={"#c46805"} shadowColor={"rgba(196,104,5,0.5)"}/>
                                    </div>




                                </div>


                            </div>
                        </div>

                    </div>
                </div>
        </div>
    );
}
