import React, {useState, useEffect} from "react";
import AdvancedSearch from "../employees/search/advanced.search";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import IntlMessages from "../../helpers/intlMessages";
import {AdaSelectBuilder} from "@adaming/ada-react-business-component";
import {
    ASSIGNMENT_NAME,
    BILLING_NAME,
    CRM_NAME,
    EMPLOYEE_NAME,
    getHostByAppName,
    NOMENCLATURE_NAME,
    ORGANIZATION_NAME
} from "../../service/host";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {Controller, useForm} from "react-hook-form";
import * as specificService from "../../service/employee.service";
import LatestOrgActivity from "./components/InfoDoughnutWidget";
import {buildingEnterprise, getYear, identity, month, months, people1, year} from "../../helpers/constants";
import PageviewsChartWidget from "../../components/widgets/pageviewsChartWidget/PageviewsChartWidget";
import * as service from "../../service/crud.service";
import DoughnutChartComponent from "./components/doughnutChartComponent";
import amountFormat from "../commun/amount.format";
import PageviewsBartWidget from "../../components/widgets/pageviewsChartWidget/PageviewsBarWidget";
import { Label } from "reactstrap";

export default function ClientStatistic(props) {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [clientGroupList, setClientGroupList] = useState([]);
    const [clientGroup, setClientGroup] = useState("");
    const [allClientsList, setAllClientsList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [client, setClient] = useState({});
    const [yearList, setYearList] = useState(getYear());
    const [salesObject, setSalesObject] = useState({})
    const [leftToPayObject, setLeftToPayObject] = useState({})
    const [contractNumberObject, setContractNumberObject] = useState({})
    const [errorMessage, setErrorMessage] = React.useState("");

    const [object, setObject] = useState({
        client: null,
        year: null,
        clientGroup: null,
    });

    useEffect(()=>{
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients/sortedClients`, (data) => {
            setAllClientsList(data);
            setClientList(data);
            setClientGroupList(Array.from(new Set(data.map(c => (c.object.group)))).map(group => ({
                key: group,
                label: group,
                value: group,
                object: group
            })));
        });

    },[])

    const onSubmit = data => {
        if(!object.client && !object.clientGroup){
            setErrorMessage("Le client ou le groupe de client sont obligatoire");
        }else if(!object.year){
            setErrorMessage("L'année est obligatoire");
        }else{
            setErrorMessage("");
            const defaultString = (s) => s ? s : "";
            service.get(getHostByAppName(BILLING_NAME),`reporting/client/salesByYear?clientGroup=${defaultString(object.clientGroup)}&idClient=${defaultString(object.client)}&year=${object.year}`,callbackResSales,(error)=>console.log("error sales call",error))
            service.get(getHostByAppName(BILLING_NAME),`reporting/client/leftToPayByYear?clientGroup=${defaultString(object.clientGroup)}&idClient=${defaultString(object.client)}&year=${object.year}`,callbackResLeftToPay,(error)=>console.log("error leftToPay call",error))
            service.get(getHostByAppName(ASSIGNMENT_NAME),`reporting/client/contractNumberByYear?clientGroup=${defaultString(object.clientGroup)}&idClient=${defaultString(object.client)}&year=${object.year}`,callbackResContractNumber,(error)=>console.log("error contracts number call",error))
        }
    }

    const callbackResSales = data =>{
        console.log("res sales",data)
        setSalesObject(data)
    }

    const callbackResLeftToPay = data =>{
        console.log("res Left to pay",data)
        setLeftToPayObject(data)
    }

    const callbackResContractNumber = data =>{
        console.log("res contract number",data)
        setContractNumberObject(data)
    }

    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("year", selectedOption);
        if (selectedOption !== null) {
            setValue("year", selectedOption.value);
            setObject({...object, year: selectedOption.value})
        } else {
            setValue("year", null);
            setObject({...object, year: null})
        }
    };

    const handleChangeClientGroup = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("clientGroup", selectedOption);
        if (selectedOption !== null) {
            setValue("clientGroup", selectedOption.key);
            setObject({...object, client: null, clientGroup: selectedOption.key})
            setClientGroup(selectedOption.object)
            setClientList(allClientsList.filter(c => c.object.group === selectedOption.key));
        } else {
            setValue("clientGroup", null);
            setObject({...object, clientGroup: null})
            setClientGroup({})
        }
    };

    const handleChangeClient = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("client", selectedOption);
        if (selectedOption !== null) {
            setValue("client", selectedOption.key);
            setObject({...object, client:selectedOption.key})
            setClient(selectedOption.object)
        } else {
            setValue("client", null);
            setObject({...object, client:null})
            setClient({})
        }
    };

    const dispatch = (data) =>{
        console.log("in dispatch", {...object, ...data})
        setObject({...object, ...data})
    }

    const clear = ()=>{
        reset({
            "clientGroup": null,
            "client": null,
            "year": null
        })
        setObject({})
        setClient({})
        setClientGroup({})
        setLeftToPayObject({})
        setSalesObject({})
        setContractNumberObject({})
    }

    return(
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <div className="display-3 grey--text mt-3">
                                        <Row>
                                            <Col>
                                                <Label>
                                                    <p style={{color: "red"}}> {errorMessage}</p>
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="assignment.clientGroup"/>
                                                    <Controller
                                                        as={<AdaSelect name={"clientGroup"}/>}
                                                        name={"clientGroup"}
                                                        isClearable
                                                        errors={errors}
                                                        options={clientGroupList}
                                                        onChange={handleChangeClientGroup}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="sidebar.client"/>
                                                    <Controller
                                                        as={<AdaSelect name={"client"}/>}
                                                        name={"client"}
                                                        isClearable
                                                        errors={errors}
                                                        options={clientList}
                                                        onChange={handleChangeClient}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="common.year"/>
                                                    <Controller
                                                        as={<AdaSelect name={"year"}/>}
                                                        name={"year"}
                                                        isClearable
                                                        errors={errors}
                                                        options={yearList}
                                                        onChange={handleChangeYear}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                                {/*<Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <IntlMessages id="common.du"/>
                                                                    <AdaInputText
                                                                        type="date"
                                                                        name="fromDate"
                                                                        errors={errors}
                                                                        innerRef={register}
                                                                        onBlur={e => setObject({...object, fromDate: e.target.value})}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <IntlMessages id="common.au"/>
                                                                    <AdaInputText
                                                                        type="date"
                                                                        name="toDate"
                                                                        errors={errors}
                                                                        innerRef={register}
                                                                        onBlur={e => setObject({...object, toDate: e.target.value})}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>*/}
                                            </Col>
                                            <Col className="pt-3">
                                                <FormGroup>
                                                    <AdaButton className="c-danger col-xl-5" onClick={() => clear()}>
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>
                                                    <AdaButton
                                                        className="c-warning col-xl-6"
                                                        type="submit"
                                                        style={{marginLeft: 10}}
                                                    >
                                                        <IntlMessages id="common.search"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </fieldset>
                            </form>



                        </div>
                    </div>
                    <div className="roe-card-style mt-3">
                        <div className="roe-card-body mt-3">

                            <div style={{minHeight:200}} className="row justify-content-between">
                                <div  style={{width:"99.9%"}}>
                                    <LatestOrgActivity
                                        name={"Client"}
                                        icon={buildingEnterprise}
                                        title={client.name}
                                        description={client.description ? client.description : "ND"}
                                        site={client.web ? client.web : "ND"}
                                        telephone={client.contactInformation && client.contactInformation.phone ? client.contactInformation.phone : "ND"}
                                        email={client.contactInformation && client.contactInformation.email ? client.contactInformation.email : "ND"}
                                        fax={client.contactInformation && client.contactInformation.fax ? client.contactInformation.fax : "ND"}
                                        statut={client.enable === true ? "Activé" : "Désactivé"}
                                        owner={client.owner}
                                        activityArea={client.activityArea}
                                    />

                                </div>

                                <div style={{width:"49%"}}  className="pt-2">
                                    <PageviewsBartWidget label={"Contrats de prestation:"} valueLabel={client && client.id && object.year && contractNumberObject && contractNumberObject.total ? `${contractNumberObject.total} contrats` : ""}  valueData={client && client.id && object.year && contractNumberObject && contractNumberObject.valueList ? contractNumberObject.valueList : []} borderColor={"#0f11c4"} shadowColor={"rgba(7,14,220,0.5)"}/>
                                </div>


                                <div style={{width:"49%"}} className="pt-2">
                                    <PageviewsBartWidget label={"Chiffre d'affaires :"} valueLabel={client && client.id && object.year && salesObject && salesObject.totalAmount ? amountFormat(salesObject.totalAmount) : ""} valueData={client && client.id && object.year && salesObject && salesObject.valueList ? salesObject.valueList : []} borderColor={"#09bd92"} shadowColor={"rgba(0, 196, 134, 0.5)"}/>
                                </div>

                                <div style={{width:"49%"}}  className="pt-2">
                                    <PageviewsBartWidget label={"Montant reste à payer  : "} valueLabel={client && client.id && object.year && leftToPayObject && leftToPayObject.totalAmount ? amountFormat(leftToPayObject.totalAmount) :""} valueData={client && client.id && object.year && leftToPayObject && leftToPayObject.valueList ? leftToPayObject.valueList : []} borderColor={"#c4a646"} shadowColor={"rgba(196,104,5,0.5)"}/>
                                </div>


                               {/* <div style={{width:"49%"}}  className="pt-2">
                                    <DoughnutChartComponent label={"Secteurs d'activité :"} valueData={client && client.id && object.year ? [300, 50, 100] : []} labels={client && client.id && object.year ? ["Banque", "Assurance", "Secteur public"] : []}  borderColor={"#09bd92"} shadowColor={"rgba(0, 196, 134, 0.5)"}/>
                                </div>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
