import React from "react";
import { Doughnut } from "react-chartjs-2";
import PageviewsChartWrapper from "../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";
const DoughnutChartComponent = ({label, labels, valueData, borderColor, shadowColor, valueLabel}) => {
    const data = {
        labels: labels,
        datasets: [
            {
                data: valueData,
                backgroundColor: [
                    "#c4a646",
                    "rgb(108, 117, 125)",
                    "rgb(66, 46, 98)"
                ],

                hoverBackgroundColor: [
                    "rgba(108, 117, 125, 0.5)",
                    "rgba(108, 117, 125, 0.5)",
                    "rgba(66, 46, 98,0.5)"
                ]
            }
        ]
    };
    return (
        <PageviewsChartWrapper>
            <div className="page-view-chart-widget-card">
                <div className="header fs-20 demi-bold-text row" style={{color:borderColor}}>{label}</div>
                <div className="analytics">
                    <Doughnut data={data} height={75} />
                </div>
            </div>
        </PageviewsChartWrapper>
    );
};
export default DoughnutChartComponent;