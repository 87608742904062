import React, {useEffect} from "react";
import PageviewsChartWrapper from "./pageviewsChart.style";
import {Bar, Line} from "react-chartjs-2";
import {months} from "../../../helpers/constants";
import chroma from "chroma-js";

export default function PageviewsBarstWidget({labelY, labelLY, labelTwoYAgo, valueDataY, valueDataLY, valueDataTwoYAgo, borderColor, shadowColor, valueLabelY, valueLabelLY, valueLabelTwoYAgo}){

    useEffect(()=>{

    },[labelY, labelLY, labelTwoYAgo, valueDataY, valueDataLY,valueDataTwoYAgo])

    const dataset1 = {
        label: labelY,
        fill: false,
        data: valueDataY,
        borderColor: borderColor,
        pointBorderColor: borderColor,
        pointBackgroundColor: borderColor,
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: shadowColor,
        backgroundColor: "#563c91",
        pointRadius: 4,
        pointBevelWidth: 3,
        pointBevelHighlightColor: "rgba(255, 255, 255, 0.75)",
        pointBevelShadowColor: shadowColor,
        pointHoverRadius: 6,
        pointHoverBevelWidth: 4.5,
        pointHoverInnerGlowWidth: 20,
        pointHoverInnerGlowColor: shadowColor,
        pointHoverOuterGlowWidth: 20,
        pointHoverOuterGlowColor: shadowColor
    };

    const dataset2 = {
        label: labelLY,
        fill: false,
        data: valueDataLY,
        borderColor: borderColor,
        pointBorderColor: borderColor,
        pointBackgroundColor: borderColor,
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: shadowColor,
        backgroundColor: "#ff0b0e",
        pointRadius: 4,
        pointBevelWidth: 3,
        pointBevelHighlightColor: "rgba(255, 255, 255, 0.75)",
        pointBevelShadowColor: shadowColor,
        pointHoverRadius: 6,
        pointHoverBevelWidth: 4.5,
        pointHoverInnerGlowWidth: 20,
        pointHoverInnerGlowColor: shadowColor,
        pointHoverOuterGlowWidth: 20,
        pointHoverOuterGlowColor: shadowColor
    };

    const dataset3 = {
        label: labelTwoYAgo,
        fill: false,
        data: valueDataTwoYAgo,
        borderColor: borderColor,
        pointBorderColor: borderColor,
        pointBackgroundColor: borderColor,
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: shadowColor,
        backgroundColor: "#09bd92",
        pointRadius: 4,
        pointBevelWidth: 3,
        pointBevelHighlightColor: "rgba(255, 255, 255, 0.75)",
        pointBevelShadowColor: shadowColor,
        pointHoverRadius: 6,
        pointHoverBevelWidth: 4.5,
        pointHoverInnerGlowWidth: 20,
        pointHoverInnerGlowColor: shadowColor,
        pointHoverOuterGlowWidth: 20,
        pointHoverOuterGlowColor: shadowColor
    };

    const data = {
        labels: months,
        datasets: [
            dataset1, dataset2, dataset3
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        tooltips: {
            mode: "index",
            intersect: false
        },
        hover: {
            mode: "nearest",
            intersect: true
        },
        spanGaps: true,
        scales: {
            xAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: "Month"
                    }
                }
            ],
            yAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: "Value"
                    }
                }
            ]
        }
    };

    return (
        <PageviewsChartWrapper>
            <div className="page-view-chart-widget-card">
                <div
                    className="header fs-14 demi-bold-text row">
                        <div
                            className="header fs-14 demi-bold-text col"
                            style={{ color: "#563c91" }}>
                            {labelY}{" "}
                            <div
                                className="pl-3 demi-bold-text fs-14"
                                style={{ color: "#563c91" }}>
                                {valueLabelY}
                            </div>
                        </div>
                        <div
                            className="header fs-14 demi-bold-text col"
                            style={{ color: "#ff0b0e" }}>
                            {labelLY}{" "}
                            <div
                                className="pl-3 demi-bold-text fs-14"
                                style={{ color: "#ff0b0e" }}>
                                {valueLabelLY}
                            </div>
                        </div>
                        <div
                            className="header fs-14 demi-bold-text col"
                            style={{ color: "#09bd92" }}>
                            {labelTwoYAgo}{" "}
                            <div
                                className="pl-3 demi-bold-text fs-14"
                                style={{ color: "#09bd92" }}>
                                {valueLabelTwoYAgo}
                            </div>
                        </div>
                </div>
                <div className="analytics">
                    <Bar data={data} height={240} options={options} />
                </div>
            </div>
        </PageviewsChartWrapper>
    );
};

